/**
 * Our blog-post-page
 *
 * @author David Faber
 * @copyright (c) Peritus Webdesign GmbH
 */
import React from 'react';
import PropTypes from 'prop-types';
import Seo, {SeoShape} from 'components/Seo';
import Header from 'components/Header';
import Footer from 'components/Footer';
import {LangShape} from '../../../components/Languages';
const Benefit = ({data, Blocks}) => {
    const {
        uri,
        seo,
        language,
        translations,
        acf: {
            pagebuilder: {
                layouts,
            },
        },
    } = data;


    return (<>
        <Seo seoData={seo} translations={translations} language={language} url={uri}/>
        <Header siteTitle={seo.title} translations={translations} language={language}/>
        <main>
            {layouts && layouts.map((block, index) => (<Blocks key={index} block={block}/>))}
        </main>
        <Footer/>
    </>);
};

Benefit.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired,
        seo: SeoShape.isRequired,
        ...LangShape,
        acf: PropTypes.shape({
            pagebuilder: PropTypes.shape({
                layouts: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,

};

export default Benefit;
